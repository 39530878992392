<template>
	<Menu :title="appName" />
	<slot />
	<footer class="footer footer-center mt-5 p-4 bg-base-300 text-base-content">
		<div>
			<p>
				<ClientOnly fallback-tag="a" fallback="Carregando rodapé...">
					Copyright © 2023 - 2024 - Todos os direitos reservados a {{ appName
					}}<!-- | Desenvolvido por
					<NuxtLink to="https://hayato.dev.br" target="_blank">Hayato</NuxtLink>
					com
					<Tooltip :tip="'Nuxt'">
						<i class="devicon-nuxtjs-plain colored"></i>
					</Tooltip>-->
				</ClientOnly>
			</p>
		</div>
	</footer>
	<ClientOnly fallback-tag="a" fallback="/\">
		<div class="fixed bottom-5 right-5 z-10 hidden" id="scroll-top">
			<Tooltip :class="'tooltip-left'" :tip="'Voltar ao topo'">
				<NuxtLink class="btn btn-primary" @click="useScrollTop">
					<i class="fa-solid fa-chevron-up fa-lg"></i>
				</NuxtLink>
			</Tooltip>
		</div>
	</ClientOnly>
	<div v-for="massage in listMassage">
		<input v-if="massage.description !== null" type="checkbox" :id="'massage-' + useSlugify(massage.title)" class="modal-toggle" />
		<label v-if="massage.description !== null" :for="'massage-' + useSlugify(massage.title)" class="modal cursor-pointer">
			<label class="modal-box relative" :for="'massage-' + useSlugify(massage.title)">
				<h3 class="text-lg font-bold" v-text="massage.title"></h3>
				<p class="mt-2 text-white" v-html="massage.description"></p>
			</label>
		</label>
	</div>
	<input type="checkbox" id="masseur" class="modal-toggle" />
	<label for="masseur" class="modal cursor-pointer">
		<label class="modal-box relative overflow-hidden" for="'masseur">
			<p class="mt-2 text-white overflow-hidden">
				<nuxt-img src="/image/index/masseur_2024-11-19.jpg" quality="75" class="rounded-box h-full" />
			</p>
		</label>
	</label>
	<div v-for="masseur in listMasseur">
		<input v-if="masseur.modal" type="checkbox" :id="'masseur-' + masseur.file" class="modal-toggle" />
		<label v-if="masseur.modal" :for="'masseur-' + masseur.file" class="modal cursor-pointer">
			<label class="modal-box relative overflow-hidden" :for="'masseur-' + masseur.file">
				<h3 class="text-lg font-bold" v-text="masseur.name"></h3>
				<p class="mt-2">
					<nuxt-img class="mx-auto" :src="`/image/index/masseur/${masseur.file}.jpg`" :title="masseur.name" />
				</p>
			</label>
		</label>
	</div>
</template>

<script setup lang="ts">
const listMassage: MassageList[] = useMassageList()
const listMasseur: MasseurList[] = useMasseurList()

useSeoMeta({
	ogTitle: appName,
	ogDescription: appDescription,
	ogImage: 'https://micaellemassagem.com.br/image/logo/mark.png',
	twitterCard: 'summary'
})

useHead({
	htmlAttrs: {
		lang: 'pt-br',
		'data-theme': 'luxury'
	},
	base: 'https://micaellemassagem.com.br',
	meta: [
		{
			name: 'description',
			content: appDescription
		},
		{
			name: 'keywords',
			content: appKeywords
		},
		{
			name: 'msapplication-TileColor',
			content: '#ffffff'
		},
		{
			name: 'msapplication-TileImage',
			content: '/favicon/ms-icon-144x144.png'
		},
		{
			name: 'theme-color',
			content: '#ffffff'
		}
	],
	title: `${appName} - ${appDescription}`,
	link: [
		{
			rel: 'apple-touch-icon',
			sizes: '57x57',
			href: '/favicon/apple-icon-57x57.png'
		},
		{
			rel: 'apple-touch-icon',
			sizes: '60x60',
			href: '/favicon/apple-icon-60x60.png'
		},
		{
			rel: 'apple-touch-icon',
			sizes: '72x72',
			href: '/favicon/apple-icon-72x72.png'
		},
		{
			rel: 'apple-touch-icon',
			sizes: '76x76',
			href: '/favicon/apple-icon-76x76.png'
		},
		{
			rel: 'apple-touch-icon',
			sizes: '114x114',
			href: '/favicon/apple-icon-114x114.png'
		},
		{
			rel: 'apple-touch-icon',
			sizes: '120x120',
			href: '/favicon/apple-icon-120x120.png'
		},
		{
			rel: 'apple-touch-icon',
			sizes: '144x144',
			href: '/favicon/apple-icon-144x144.png'
		},
		{
			rel: 'apple-touch-icon',
			sizes: '152x152',
			href: '/favicon/apple-icon-152x152.png'
		},
		{
			rel: 'apple-touch-icon',
			sizes: '180x180',
			href: '/favicon/apple-icon-180x180.png'
		},
		{
			rel: 'icon',
			type: 'image/png',
			sizes: '96x96',
			href: '/favicon/android-icon-96x96.png'
		},
		{
			rel: 'icon',
			type: 'image/png',
			sizes: '192x192',
			href: '/favicon/android-icon-192x192.png'
		},
		{
			rel: 'stylesheet',
			href: 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css',
			integrity: 'sha512-iecdLmaskl7CVkqkXNQ/ZH/XLlvWZOJyj7Yy7tcenmpD1ypASozpmT/E0iPtmFIB46ZmdtAc9eNBvH0H/ZpiBw==',
			crossorigin: 'anonymous',
			referrerpolicy: 'no-referrer'
		},
		{
			rel: 'stylesheet',
			href: 'https://cdnjs.cloudflare.com/ajax/libs/devicon/2.15.1/devicon.min.css',
			integrity: 'sha512-X2hbziex+UPQ+KdI4BWI+39jEDhf2Smc6bfFI/FTIzWnt7mbwZCUU4RWalnMfxtHCpISlexzmd2kvPFJCliiPA==',
			crossorigin: 'anonymous',
			referrerpolicy: 'no-referrer'
		}
	],
	script: [
		{
			async: true,
			src: 'https://www.googletagmanager.com/gtag/js?id=G-29H1X8BRGQ',
			tagPosition: 'head'
		},
		{
			innerHTML: `window.dataLayer = window.dataLayer || []
  function gtag(){dataLayer.push(arguments)}
  gtag('js', new Date())

  gtag('config', 'G-29H1X8BRGQ')`,
			tagPosition: 'head'
		}
	]
})

onMounted(() => {
	window.onscroll = () => {
		const elementScrollButton = document.getElementById('scroll-top') as HTMLDivElement

		if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
			elementScrollButton.classList.remove('hidden')
		} else {
			elementScrollButton.classList.add('hidden')
		}
	}
})
</script>
